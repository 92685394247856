import "./popup.css"
import React, {FC} from "react";
import {PopUpTypes} from "../../types/popUp.types";

interface PopupProps extends PopUpTypes {
	handleAnswer: (agree: boolean) => void
}

export const Popup: FC<PopupProps> = ({message, isSecondButton, handleAnswer }) => {
	
	return (
		<>
			<div className="popup__wrapper">
				<div className="popup__container">
					<p className="popup__text">{message}</p>
					{
						isSecondButton
							?
							(
								<div className="popup__button-container">
									<button className="popup__button"
									        onClick={() => handleAnswer(true)}>
										Да
									</button>
									<button className="popup__button"
									        onClick={() => handleAnswer(false)}>
										Нет
									</button>
								</div>
							)
							: (
								<button className="popup__button" onClick={() => handleAnswer(false)}>Ок</button>
							)
					}
				</div>
			</div>
		</>
	)
}