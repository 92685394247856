import React, {useEffect, useRef, useState} from 'react';
import {AppRoutes} from "./appRoutes";
import Nav from "./components/nav/Nav";
import "../../offergen_client/src/main.css"

function App() {
	return (
		<div className="app">
			<Nav/>
			<AppRoutes/>
		</div>
	);
}

export default App;
