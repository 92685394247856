import {z} from "zod";

const projectPattern = /^[A-Za-zА-Яа-я0-9\s-]+$/

export const StringSchema = z.string()
	.min(1, "Поле не может быть пустым")
	.max(50, "Поле не может превышать 50 символов")
	.refine(value => projectPattern.test(value), {
		message: 'Поле не может содержать спец. символы',
	})

export const StringNumberSchema = z.string()
	.min(1, "Поле не может быть пустым")

export const NumberSchema = z.number()
	.min(1, 'Значение должно быть больше 0')

export const ProjectSchema = StringSchema

export const TaskSchema = z.object({
	id: z.string(),
	name: StringSchema,
	quantity: StringNumberSchema,
	price: z.string(),
	hours: z.string()
})

export const StepSchema = z.object({
	id: z.string(),
	stageName: StringSchema,
	quantity: z.number(),
	hours: z.number(),
	price: z.string(),
	tasks: z.array(TaskSchema)
})

export const CategorySchema = z.object({
	name_stages_estimation_works: z.string(),
	amountOfCost: z.number(),
	amountOfTime: z.number(),
	typesOfWork: z.array(z.object({
		number: z.number(),
		name_work: z.string(),
		times: z.number(),
		cost: z.number(),
	}))
})

export const EstimatesDataSchema = z.object({
	marking_length: z.number(),
	type_marking: z.number(),
	rate: StringNumberSchema,
	name_estimates: z.string(),
	categories: z.array(CategorySchema),
	totalSum: z.object({time: z.number(), cost: z.number(),})
})

export const TableDataSchema = z.object({
	projectName: StringSchema,
	rate: NumberSchema,
	selectedPeriod: z.string(),
	steps: z.array(StepSchema),
	totalProjectQuantity: z.number(),
	totalProjectHours: z.number(),
	totalProjectPrice: z.number(),
})