import React, {FC, useEffect, useRef, useState} from "react";
import "./mainTable.css";
import {PopUpTypes} from "../../types/popUp.types";
import {EstimatesData, Step, TableData, Task} from "../../types/types";
import {generateSecureId} from "../../utils/generateUuidFunc";
import {TableDataSchema} from "../../validation/schemas";
import {MEDIUM_WIDTH, PHONE_WIDTH} from "../../constants/breakpoints";
import {useGetWindowSize} from "../../hooks/useGetWindowSize";
import {z} from "zod";
import {Notification} from "../notification/Notification";
import {Popup} from "../popup/Popup";


const MainTable: FC = () => {
	const isMounted = useRef(false);
	const [estimatesData, setEstimatesData] = useState<EstimatesData>({
		categories: [],
		marking_length: 0,
		name_estimates: "",
		rate: "",
		totalSum: {cost: 0, time: 0},
		type_marking: 0,
	});
	const [steps, setSteps] = useState<Step[]>([
		{
			id: generateSecureId(),
			stageName: '',
			quantity: 0,
			hours: 0,
			price: '',
			tasks: [{id: generateSecureId(), name: '', quantity: '', price: '', hours: '0'}],
		},
	]);
	const [selectedPeriod, setSelectedPeriod] = useState<string>("day");
	const [rate, setRate] = useState<number>(0);
	const [name_estimates, set_name_estimates] = useState<string>('');
	const {windowWidth} = useGetWindowSize();
	const [errors, setErrors] = useState<any>({});
	const [isShowPopup, setIsShowPopup] = useState<PopUpTypes>({
		isOpen: false,
	});
	const [isShowNotification, setIsShowNotification] = useState<boolean>(false);
	
	const saveTableDataToLocalStorage = () => {
		// Собираем данные из таблицы в объект
		const tableData: TableData = {
			projectName: name_estimates.trimEnd(),
			rate,
			selectedPeriod,
			steps: steps.map((step) => ({
				id: step.id,
				stageName: step.stageName,
				quantity: step.quantity,
				hours: step.hours,
				price: step.price,
				tasks: step.tasks.map((task) => ({
					id: task.id,
					name: task.name,
					quantity: task.quantity,
					hours: task.hours,
					price: task.price,
				})),
			})),
			totalProjectQuantity: getTotalQuantity(),
			totalProjectHours: getTotalHours(),
			totalProjectPrice: getTotalPrice(),
		};
		localStorage.setItem("tableData", JSON.stringify(tableData));
		return tableData
	};
	
	const isTableValid = (tableData?: TableData) => {
		const validationResult = TableDataSchema.safeParse(tableData)
		let isValid: boolean;
		if (validationResult.success) {
			localStorage.setItem("tableData", JSON.stringify(tableData));
			isValid = true;
			setErrors({_errors: []});
		} else {
			localStorage.setItem("tableData", JSON.stringify(tableData));
			
			const formattedErrors = validationResult.error.errors.reduce((acc: any, item, index, array) => {
				// Форматируем ошибки в удобный объект
				const errName = item.path.join('.')
				const errText = item.message
				const errArrayText: string[] = []
				const errNamePrev = index >= 1 && array[index - 1].path.join('.')
				const errTextPrev = index >= 1 && array[index - 1].message
				if (errName === errNamePrev) {
					errArrayText.push(errTextPrev as string, errText)
				} else {
					errArrayText.push(errText)
				}
				return {
					...acc,
					[errName]: errArrayText,
				};
			}, {});
			isValid = false;
			setErrors(formattedErrors)
		}
		return isValid
	}
	
	useEffect(() => {
		const storedTableData = localStorage.getItem("tableData");
		if (storedTableData) {
			try {
				const parsedTableData = JSON.parse(storedTableData);
				set_name_estimates(parsedTableData.projectName || "");
				setRate(parsedTableData.rate || 0);
				setSelectedPeriod(parsedTableData.selectedPeriod || "day");
				setSteps(parsedTableData.steps || []);
			} catch (error) {
				console.error("Ошибка при загрузке данных из localStorage:", error);
			}
		}
	}, []);
	
	useEffect(() => {
		if (isMounted.current) {
			saveTableDataToLocalStorage()
			// updateEstimatesData()
		} else {
			isMounted.current = true;
		}
	}, [steps, selectedPeriod, name_estimates, rate]);
	
	const updateEstimatesData = () => {
		setEstimatesData((prevData) => {
			let startMarker = 1; // Начальное значение для первого этапа
			let endMark = 0; // Максимальное значение end для задач
			let numberTask = 0; // Счетчик для нумерации задач
			
			// Используйте словарь для отслеживания задач с одинаковым id
			const tasksNumber: { [key: string]: number } = {};
			
			// Обновление данных estimatesData
			const updatedData = {
				...prevData,
				marking_length: 0, // Обнуляем marking_length в начале
				type_marking: selectedPeriod === "day" ? 0 : 1, // Устанавливаем type_marking на основе selectedPeriod
				rate: rate.toString(), // Преобразуем rate в строку
				name_estimates: name_estimates, // Устанавливаем name_estimates
				categories: steps.map((step) => {
					// Вычисление amountOfCost и amountOfTime
					const amountOfCost = parseFloat(step.price);
					const amountOfTime = step.hours;
					
					// Обработка каждой задачи
					const typesOfWork = step.tasks?.map((task) => {
						// Увеличиваем счетчик задач
						numberTask += 1;
						
						// Определяем начальную отметку
						let start: number;
						if (tasksNumber[task.id]) {
							// Если задача с этим id уже существует, используем начальную отметку из tasksNumber
							start = tasksNumber[task.id];
						} else {
							// В противном случае используем текущий startMarker
							start = startMarker;
						}
						
						// Вычисляем end
						const end = start + parseFloat(task.quantity) - 1;
						
						// Создаем marker_are_long
						const markerAreLong = Array.from({length: end - start + 1}, (_, i) => start + i);
						
						// Обновляем startMarker для следующей задачи
						startMarker = end + 1;
						
						// Обновляем endMark
						endMark = Math.max(endMark, end);
						
						// Обновляем tasksNumber
						tasksNumber[task.id] = start;
						
						// Возвращаем объект для текущей задачи
						return {
							number: parseFloat(numberTask.toString()),
							name_work: task.name,
							times: parseFloat(task.hours),
							cost: parseFloat(task.price),
							marker_are_long: markerAreLong,
						};
					});
					
					// Возвращаем объект для текущего этапа
					return {
						name_stages_estimation_works: step.stageName,
						amountOfCost,
						amountOfTime,
						typesOfWork,
					};
				}),
				totalSum: {
					time: getTotalHours(),
					cost: getTotalPrice(),
				},
			};
			
			// Устанавливаем marking_length равным endMark
			updatedData.marking_length = endMark;
			
			// Сохраняем данные в localStorage
			localStorage.setItem("estimatesData", JSON.stringify({estimates: updatedData}));
			
			// Возвращаем обновленные данные
			return updatedData;
		});
		
		// Сохраняем данные таблицы в localStorage
		saveTableDataToLocalStorage();
	};
	
	const handleSaveTable = () => {
		isTableValid(saveTableDataToLocalStorage())
		updateEstimatesData()
		if(isTableValid(saveTableDataToLocalStorage())) setIsShowNotification(true)
	}
	
	const handleAddStep = () => {
		setSteps((prevSteps) => [
			...prevSteps,
			{
				id: generateSecureId(),
				stageName: '',
				quantity: 0,
				hours: 0,
				price: '',
				tasks: [{id: generateSecureId(), quantity: '', hours: '', price: '', name: ''}],
			},
		]);
		setTimeout(() => {
			updateEstimatesData();
		}, 0);
		
		// Обновляем tableData в localStorage
		setTimeout(() => {
			saveTableDataToLocalStorage();
		}, 0);
		
	};
	
	const handleStepChange = (e: React.ChangeEvent<HTMLInputElement>, stepIndex: number) => {
		setErrors({_errors: []})
		setSteps((prevSteps) => {
			const updatedSteps = [...prevSteps];
			updatedSteps[stepIndex].stageName = e.target.value;
			return updatedSteps;
		})
	}
	
	const handleTaskChange = (stepIndex: number, taskIndex: number, key: keyof Task) => (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		setErrors({_errors: []})
		const value = e.target.value;
		if (key === "name") {
			value.trimStart()
				.replace('  ', ' ')
				.replaceAll("<", "&lt;")
				.replaceAll(">", "&gt;")
		}
		if (key === "quantity") {
			if (!z.string().regex(/^[1-9]\d*$/).safeParse(e.target.value).success && e.target.value != '')
				return;
		}
		setSteps((prevSteps) => {
			
			const updatedSteps = [...prevSteps];
			
			// @ts-ignore
			updatedSteps[stepIndex].tasks[taskIndex][key] = value;
			// console.log('updatedSteps[stepIndex].tasks[taskIndex][key]', updatedSteps[stepIndex].tasks[taskIndex][key])
			
			if (key === "quantity" || key === "hours") {
				
				const task = updatedSteps[stepIndex].tasks[taskIndex];
				
				const quantity = parseFloat(task.quantity) || 0;
				const hours = selectedPeriod === "day" ? quantity * 8 : quantity * 40;
				const price = hours * rate;
				
				task.hours = hours.toString();
				task.price = price.toString();
				
				
				updatedSteps[stepIndex].quantity = updatedSteps[stepIndex].tasks.reduce((acc, task) => {
					return acc + (parseFloat(task.quantity) || 0);
				}, 0);
				
				updatedSteps[stepIndex].hours = updatedSteps[stepIndex].tasks.reduce((acc, task) => {
					return acc + (parseFloat(task.hours) || 0);
				}, 0);
				updatedSteps[stepIndex].price = updatedSteps[stepIndex].tasks.reduce((acc, task) => {
					return acc + (parseFloat(task.price) || 0);
				}, 0).toString();
			}
			
			const totalQuantity = getTotalQuantity();
			const totalHours = getTotalHours();
			const totalPrice = getTotalPrice();
			
			// Обновляем данные estimatesData
			setEstimatesData((prevData) => {
				const updatedData = {
					...prevData,
					marking_length: totalQuantity,
					totalSum: {
						time: totalHours,
						cost: totalPrice,
					},
				};
				
				// Сохранение данных в localStorage.
				localStorage.setItem("estimatesData", JSON.stringify(updatedData));
				
				return updatedData;
			});
			
			updateEstimatesData();
			return updatedSteps;
		})
		;
	};
	
	const getTotalQuantity = () => {
		if (!steps || steps.length === 0) {
			return 0;
		}
		return steps.reduce((acc, step) => {
			const quantity = step.quantity ? parseFloat(step.quantity.toString()) : 0;
			return acc + quantity;
		}, 0);
	};
	
	const getTotalHours = () => {
		if (!steps || steps.length === 0) {
			return 0;
		}
		return steps.reduce((acc, step) => {
			const hours = step.hours ? parseFloat(step.hours.toString()) : 0;
			return acc + hours;
		}, 0);
	};
	
	const getTotalPrice = () => {
		if (!steps || steps.length === 0) {
			return 0;
		}
		return steps.reduce((acc, step) => {
			const price = step.price ? parseFloat(step.price.toString()) : 0;
			return acc + price;
		}, 0);
	};
	
	const handleRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setErrors({_errors: []})
		const value = e.target.value;
		if (/^\d*$/.test(value) && value.length <= 7) {
			setRate(parseFloat(value) || 0);
		}
		setTimeout(() => {
			updateEstimatesData();
		}, 0);
		
		// Обновляем tableData в localStorage
		setTimeout(() => {
			saveTableDataToLocalStorage();
		}, 0);
	};
	
	const handleNameEstimates = (e: React.ChangeEvent<HTMLInputElement>) => {
		setErrors({_errors: []})
		set_name_estimates(e.target.value
			.trimStart()
			.replace('  ', ' ')
			.replaceAll("<", "&lt;")
			.replaceAll(">", "&gt;"));
		
		setTimeout(() => {
			updateEstimatesData();
		}, 0);
		
		// Обновляем tableData в localStorage
		setTimeout(() => {
			saveTableDataToLocalStorage();
		}, 0);
	};
	
	useEffect(() => {
		setTimeout(() => {
			updateEstimatesData();
		}, 0);
		setTimeout(() => {
			saveTableDataToLocalStorage();
		}, 0);
	}, [steps, selectedPeriod, name_estimates, rate]);
	
	const handlePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedPeriod(e.target.value);
	};
	
	useEffect(() => {
		updateTable();
	}, [selectedPeriod, rate]);
	
	const updateTable = () => {
		setSteps((prevSteps) => {
			const updatedSteps = [...prevSteps];
			updatedSteps.forEach((step) => {
				step.tasks.forEach((task) => {
					const quantity = parseFloat(task.quantity) || 0;
					const hours = selectedPeriod === "day" ? quantity * 8 : quantity * 40;
					const price = hours * rate;
					task.hours = hours.toString();
					task.price = price.toString();
				});
				
				step.quantity = step.tasks.reduce((acc, task) => {
					const taskQuantity = task.quantity ? parseFloat(task.quantity) : 0;
					return acc + taskQuantity;
				}, 0);
				
				step.hours = step.tasks.reduce((acc, task) => {
					const taskHours = task.hours ? parseFloat(task.hours) : 0;
					return acc + taskHours;
				}, 0);
				step.price = step.tasks.reduce((acc, task) => {
					const taskPrice = task.price ? parseFloat(task.price) : 0;
					return acc + taskPrice;
				}, 0).toString();
			});
			return updatedSteps;
		});
	};
	
	const handleDownloadTable = async (e: any): Promise<Blob | void> => {
		e.preventDefault()
		if (!isTableValid(saveTableDataToLocalStorage())) {
			// Показываем сообщение об ошибке, если поля не заполнены
			setIsShowPopup({
				isOpen: true,
				message: 'Пожалуйста, заполните все поля перед скачиванием таблицы',
				isSecondButton: false
			})
			return;
		} else {
			const estimatesDataString = localStorage.getItem('estimatesData');
			
			if (estimatesDataString) {
				const estimatesData = JSON.parse(estimatesDataString);
				
				const nameEstimates: string = estimatesData.estimates.name_estimates;
				setIsShowPopup({
					isOpen: true,
					message: `Таблица будет скачана с названием ${nameEstimates}.xlsx`,
					isSecondButton: false
				})
				
				const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/estimates/downloadExcelFile`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(estimatesData),
				})
					
					.then((response) => response.blob())
					.then((blob) => {
						const url = window.URL.createObjectURL(
							new Blob([blob]),
						);
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute(
							'download',
							`${nameEstimates}.xlsx`
						);
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					})
					.catch((error) => {
						console.error('Ошибка запроса', error);
					})
				
			}
		}
		
	}
	const handleAddTaskToStep = (stepIndex: number) => {
		setSteps((prevSteps) => {
			const updatedSteps = [...prevSteps];
			const newTaskId = updatedSteps[stepIndex].tasks.length + 1;
			const newTask = {
				id: generateSecureId(),
				name: '',
				quantity: '',
				price: '0',
				hours: '0',
			};
			updatedSteps[stepIndex].tasks.push(newTask);
			return updatedSteps;
		});
		setTimeout(() => {
			updateEstimatesData();
		}, 0);
		
		// Обновляем tableData в localStorage
		setTimeout(() => {
			saveTableDataToLocalStorage();
		}, 0);
	};
	
	const handleDeleteStepClick = (stepIndex: number) => {
		setIsShowPopup({
			isOpen: true,
			message: 'Вы точно хотите удалить этап?',
			isSecondButton: true,
			stepIndex: stepIndex
		})
	}
	
	const handleRemoveStep = (stepIndex: number) => {
		setSteps((prevSteps) => {
			const updatedSteps = [...prevSteps];
			if (updatedSteps.length <= 1) {
				setIsShowPopup({
					isOpen: true,
					message: 'Нельзя удалить единственный этап',
					isSecondButton: false
				})
				return updatedSteps
			}
			updatedSteps.splice(stepIndex, 1);
			updateEstimatesData();
			saveTableDataToLocalStorage();
			return updatedSteps;
		});
	};
	
	const handleDeleteTaskClick = (stepIndex: number, taskIndex: number, taskId: string) => {
		setIsShowPopup({
			isOpen: true,
			message: 'Вы точно хотите удалить задачу?',
			isSecondButton: true,
			stepIndex: stepIndex,
			taskIndex: taskIndex,
			taskId: taskId
		})
	}
	
	const handleRemoveTaskFromStep = (stepIndex: number, taskIndex: number, taskId: string) => {
		setSteps((prevSteps) => {
			// Создаем копию массива steps и удаляем задачу из заданного этапа по индексу задачи
			const updatedSteps = [...prevSteps];
			if (updatedSteps[stepIndex].tasks.length <= 1) {
				setIsShowPopup({
					isOpen: true,
					message: 'Нельзя удалить единственную задачу',
					isSecondButton: false
				})
				return updatedSteps
			}
			updatedSteps[stepIndex].tasks.splice(taskIndex, 1);
			recalculateStepValues(stepIndex);
			recalculateTotalSum();
			return updatedSteps;
		});
		setTimeout(() => {
			updateEstimatesData();
			saveTableDataToLocalStorage();
		}, 0);
	};
	const recalculateStepValues = (stepIndex: number) => {
		const step = steps[stepIndex];
		let totalQuantity = 0;
		let totalHours = 0;
		let totalCost = 0;
		
		// Пересчет значений для этапа
		step.tasks.forEach((task) => {
			totalQuantity += parseFloat(task.quantity) | 0;
			totalHours += parseFloat(task.hours) | 0;
			totalCost += parseFloat(task.price) | 0;
		});
		step.quantity = totalQuantity ? totalQuantity : 0;
		step.hours = totalHours;
		step.price = totalCost.toString();
	};
	const recalculateTotalSum = () => {
		let totalTime = 0;
		let totalCost = 0;
		
		// Пересчет итоговых значений на основе всех этапов
		steps.forEach((step) => {
			totalTime += parseFloat(step.hours.toString());
			totalCost += parseFloat(step.price);
		});
		
		// Обновление totalSum в estimatesData
		setEstimatesData((prevData) => ({
			...prevData,
			totalSum: {
				time: totalTime,
				cost: totalCost,
			},
		}));
	};
	
	const handleDeleteTableClick = () => {
		setIsShowPopup({
			isOpen: true,
			message: 'Вы точно хотите удалить таблицу?',
			isSecondButton: true
		})
	}
	
	const handleCloseNotification = () => {
		setIsShowNotification(false);
	};
	
	const handleAnswer = (agree: boolean) => {
		if (agree) {
			if (isShowPopup.message?.includes('таблицу')) clearTable()
			if (isShowPopup.message?.includes('этап')) {
				handleRemoveStep(isShowPopup?.stepIndex as number)
			}
			if (isShowPopup.message?.includes('задачу')){
				handleRemoveTaskFromStep(isShowPopup?.stepIndex as number, isShowPopup?.taskIndex as number, isShowPopup?.taskId as string)
			}
		}
		setIsShowPopup({
			isOpen: false
		})
	}
	
	const clearTable = () => {
		setErrors({_errors: []})
		setSteps([
			{
				id: generateSecureId(),
				stageName: '',
				quantity: 0,
				hours: 0,
				price: '',
				tasks: [{id: generateSecureId(), name: '', quantity: '', price: '0', hours: '0'}],
			},
		]);
		set_name_estimates('')
		setRate(0)
		localStorage.removeItem('estimatesData');
		localStorage.removeItem('tableData');
	}
	
	return (
		<>
			<div className="container">
				<div className="functional-menu">
					<div className="inputs">
						<div className="pr name">
							<p className={errors.projectName ? 'errorText' : 'text'}>Имя</p>
							<div className="input-item">
								<input id="name-project"
								       className={errors.projectName ? 'input-input errorInput' : 'input-input'}
								       placeholder="Название проекта"
								       value={name_estimates}
								       onChange={handleNameEstimates}
								/>
								{errors.projectName && <div className={"errorText"}>{errors.projectName[0]}</div>}
							</div>
						</div>
						
						<div className="pr rate">
							<p className={errors.rate ? 'errorText' : 'text'}>Тариф</p>
							<div className="input-item">
								<input id="rate-project"
								       type="text"
								       minLength={1}
								       maxLength={7}
								       value={rate}
								       className={errors.rate ? 'input-input errorInput' : 'input-input'}
								       onChange={handleRateChange}
								/>
								{errors.rate && <div className={"errorText"}>{errors.rate[0]}</div>}
							</div>
						</div>
					</div>
					<div className="buttons">
						<button className="save-btn" onClick={handleSaveTable}>
							Сохранить
						</button>
						<button className="dwnld" id="dwnld-table" onClick={handleDownloadTable}>
							Скачать таблицу
						</button>
						<button className="dlt-button" id="dlt" onClick={handleDeleteTableClick}></button>
					</div>
				</div>
				<div className="add-btns">
					<button className="add" onClick={handleAddStep}>
						<p>Добавить этап</p>
						<img src="/Pictures/plus.svg" alt="Add stage"/>
					</button>
				</div>
				{windowWidth >= PHONE_WIDTH ?
					<table>
						<thead>
						<tr>
							<td colSpan={2}>
								Этап
							</td>
							<td colSpan={2}>
								<p className="period-title">Период</p>
								<select value={selectedPeriod} onChange={handlePeriodChange}>
									<option value="day">Дни</option>
									<option value="week">Недели</option>
								</select>
							</td>
							<td colSpan={2}>
								Итог этапа
							</td>
						</tr>
						<tr>
							<td colSpan={2}>
								Задача
							</td>
							<td className="quantity">{windowWidth >= MEDIUM_WIDTH ? 'Количество' : 'Кол-во'}</td>
							<td className="hours">Часы</td>
							<td>Цена</td>
						</tr>
						</thead>
						<tbody>
						{steps.map((step, stepIndex) => (
							<React.Fragment key={step.id}>
								<tr className="stage-row">
									<td colSpan={2}
									    className={errors[`steps.${stepIndex}.stageName`] ? "stage-name table-input-error" : "stage-name"}
									>
										<div className="stage">
											<div className="table-input">
												<input
													className="table-input stage"
													placeholder="Наименование этапа"
													type="text"
													value={step.stageName
														.trimStart()
														.replace('  ', ' ')
														.replaceAll("<", "&lt;")
														.replaceAll(">", "&gt;")}
													onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
														handleStepChange(e, stepIndex)
													}}
												/>
												{errors[`steps.${stepIndex}.stageName`] &&
													<div
														className={"table-input-error-text"}>{errors[`steps.${stepIndex}.stageName`][0]}</div>}
											</div>
											<button className="addTaskToStage"
											        onClick={() => handleAddTaskToStep(stepIndex)}></button>
											<button className="deleteStage"
											        onClick={() => handleDeleteStepClick(stepIndex)}></button>
										</div>
									
									</td>
									<td className="count">
										<input
											className="table-input"
											placeholder="0"
											type="text"
											value={step.quantity}
											readOnly
										/>
									</td>
									<td className="td-hours">
										<input
											className="table-input"
											placeholder="0"
											type="text"
											value={step.hours}
											readOnly
										/>
									</td>
									<td>
										<input
											className="table-input"
											placeholder="0"
											type="text"
											value={step.price}
											readOnly
										/>
									</td>
								</tr>
								{step.tasks.map((task, taskIndex) => (
									<tr key={task.id}>
										<td className="task-num">
											<input
												type="text"
												className="table-input"
												placeholder="0"
												value={taskIndex + 1}
												onChange={handleTaskChange(stepIndex, taskIndex, "id")}
												readOnly
											/>
										</td>
										<td
											className={errors[`steps.${stepIndex}.tasks.${taskIndex}.name`] ? "task-name table-input-error" : "task-name"}>
											<div className="taskDiv">
												<div className={"table-input"}>
													<input
														className="table-input task"
														placeholder="Наименование задачи"
														type="text"
														value={task.name
															.trimStart()
															.replace('  ', ' ')
															.replaceAll("<", "&lt;")
															.replaceAll(">", "&gt;")}
														onChange={handleTaskChange(stepIndex, taskIndex, "name")}
													/>
													{errors[`steps.${stepIndex}.tasks.${taskIndex}.name`] &&
														<div
															className={"table-input-error-text"}>{errors[`steps.${stepIndex}.tasks.${taskIndex}.name`][0]}</div>}
												</div>
												
												<button className="delete-task"
												        onClick={() => handleDeleteTaskClick(stepIndex, taskIndex, task.id)}></button>
											</div>
										
										</td>
										<td
											className={errors[`steps.${stepIndex}.tasks.${taskIndex}.quantity`] ? "count table-input-error" : "count"}>
											<input
												className="table-input"
												placeholder="0"
												minLength={1}
												maxLength={2}
												type="text"
												pattern="\d*"
												value={task.quantity}
												onChange={handleTaskChange(stepIndex, taskIndex, "quantity")}
											/>
											{errors[`steps.${stepIndex}.tasks.${taskIndex}.quantity`] &&
												<div
													className={"table-input-error-text"}>{errors[`steps.${stepIndex}.tasks.${taskIndex}.quantity`][0]}</div>}
										</td>
										<td className="td-hours">
											<input
												className="table-input"
												placeholder="0"
												type="text"
												value={task.hours}
												readOnly
												onChange={handleTaskChange(stepIndex, taskIndex, "hours")}
											/>
										</td>
										<td>
											<input
												className="table-input"
												placeholder="0"
												value={task.price}
												type="text"
												readOnly
												onChange={handleTaskChange(stepIndex, taskIndex, "price")}
											/>
										</td>
									</tr>
								))}
							</React.Fragment>
						))}
						<tr className="results-row">
							<td className="result-title" colSpan={2}>
								Итого
							</td>
							<td className="result">{getTotalQuantity()}</td>
							<td className="result">{getTotalHours()}</td>
							<td>{getTotalPrice()}</td>
						</tr>
						</tbody>
					</table>
					:
					// Мобильная верстка
					<table>
						<thead>
						<tr className="first-title">
							<td className="upLeft-border upRight-border" colSpan={3}>
								<p>Этап</p>
								<select value={selectedPeriod} onChange={handlePeriodChange}>
									<option value="day">Дни</option>
									<option value="week">Недели</option>
								</select>
							</td>
						</tr>
						<tr>
							<td className="quantity">Кол-во</td>
							<td className="hours">Часы</td>
							<td>Цена</td>
						</tr>
						<tr>
							<td colSpan={3}>
								Задача
							</td>
						</tr>
						<tr>
							<td className="quantity">{windowWidth >= MEDIUM_WIDTH ? 'Количество' : 'Кол-во'}</td>
							<td className="hours">Часы</td>
							<td>Цена</td>
						</tr>
						</thead>
						<tbody>
						{steps.map((step, stepIndex) => (
							<React.Fragment key={step.id}>
								<tr className="stage-row">
									<td colSpan={3}
									    className={errors[`steps.${stepIndex}.stageName`] ? "stage-name table-input-error" : "stage-name"}
									>
										<div className="stage">
											<div className="table-input">
												<input
													className="table-input stage"
													placeholder="Наименование этапа"
													type="text"
													value={step.stageName
														.trimStart()
														.replace('  ', ' ')
														.replaceAll("<", "&lt;")
														.replaceAll(">", "&gt;")}
													onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
														handleStepChange(e, stepIndex)
													}}
												/>
												{errors[`steps.${stepIndex}.stageName`] &&
													<div
														className={"table-input-error-text"}>{errors[`steps.${stepIndex}.stageName`][0]}</div>}
											</div>
											<button className="addTaskToStage"
											        onClick={() => handleAddTaskToStep(stepIndex)}></button>
											<button className="deleteStage"
											        onClick={() => handleRemoveStep(stepIndex)}></button>
										</div>
									</td>
								</tr>
								<tr className="stage-row">
									<td className="count">
										<input
											className="table-input"
											placeholder="0"
											type="text"
											value={step.quantity}
											readOnly
										/>
									</td>
									<td>
										<input
											className="table-input"
											placeholder="0"
											type="text"
											value={step.hours}
											readOnly
										/>
									</td>
									<td>
										<input
											className="table-input"
											placeholder="0"
											type="text"
											value={step.price}
											readOnly
										/>
									</td>
								</tr>
								{step.tasks.map((task, taskIndex) => (
									<React.Fragment>
										<tr key={task.id}>
											<td
												colSpan={3}
												className={errors[`steps.${stepIndex}.tasks.${taskIndex}.name`] ? "task-name table-input-error" : "task-name"}>
												<div className="taskDiv">
													<div className={"table-input"}>
														<input
															className="table-input task"
															placeholder="Наименование задачи"
															type="text"
															value={task.name
																.trimStart()
																.replace('  ', ' ')
																.replaceAll("<", "&lt;")
																.replaceAll(">", "&gt;")}
															onChange={handleTaskChange(stepIndex, taskIndex, "name")}
														/>
														{errors[`steps.${stepIndex}.tasks.${taskIndex}.name`] &&
															<div
																className={"table-input-error-text"}>{errors[`steps.${stepIndex}.tasks.${taskIndex}.name`][0]}</div>}
													</div>
													
													<button className="delete-task"
													        onClick={() => handleRemoveTaskFromStep(stepIndex, taskIndex, task.id)}></button>
												</div>
											
											</td>
										</tr>
										<tr>
											<td
												className={errors[`steps.${stepIndex}.tasks.${taskIndex}.quantity`] ? "table-input-error" : ""}>
												<input
													className="table-input"
													placeholder="0"
													minLength={1}
													maxLength={6}
													type="text"
													pattern="\d*"
													value={/^\d*$/.test(task.quantity) ? task.quantity.startsWith('0') ? task.quantity.replace('0', '') : task.quantity : ''}
													onChange={handleTaskChange(stepIndex, taskIndex, "quantity")}
												/>
												{errors[`steps.${stepIndex}.tasks.${taskIndex}.quantity`] &&
													<div
														className={"table-input-error-text"}>{errors[`steps.${stepIndex}.tasks.${taskIndex}.quantity`][0]}</div>}
											</td>
											<td>
												<input
													className="table-input"
													placeholder="0"
													type="text"
													value={task.hours}
													readOnly
													onChange={handleTaskChange(stepIndex, taskIndex, "hours")}
												/>
											</td>
											<td>
												<input
													className="table-input"
													placeholder="0"
													value={task.price}
													type="text"
													readOnly
													onChange={handleTaskChange(stepIndex, taskIndex, "price")}
												/>
											</td>
										</tr>
									</React.Fragment>
								))}
							</React.Fragment>
						))}
						<tr className="results-row">
							<td className="result-title" colSpan={2}>
								Итого
							</td>
						</tr>
						<tr className="results-row">
							<td className="result">{getTotalQuantity()}</td>
							<td className="result">{getTotalHours()}</td>
							<td>{getTotalPrice()}</td>
						</tr>
						</tbody>
					</table>
				}
			</div>
			{isShowPopup.isOpen
				&&
				<Popup message={isShowPopup.message}
				       isSecondButton={isShowPopup.isSecondButton}
				       handleAnswer={handleAnswer}
				/>
			}
			{isShowNotification && <Notification message={'Ваша таблица сохранена в браузере'} onClose={handleCloseNotification}/>}
		</>
	);
};
export default MainTable;
