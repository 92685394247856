import React, {useEffect, useState} from "react";
import exp from "node:constants";
import "../privacy/privacy.css"
const Privacy = () => {
    const downloadPrivacy = () => {
        const doc = '/privacy/privacy.docx';
        const link = document.createElement('a');
        link.href = doc;
        link.setAttribute('download', 'privacy.docx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    return(
        <div className="pr-container">
            <div className="privacy-container">
                <h2 className="pr-title">Политика конфиденциальности</h2>
                <p className="pr-text">Данное приложение предназначено для создания, формирования и редактирования смет для коммерческих
                    предложений, для контрагентов и контроля рабочего времени внутри организаций.
                    Мы собираем, храним и обрабатываем следующие данные:</p>
                <ul className="data-points">
                    <li>IP-адрес, с которого осуществляется подключение к приложению;</li>
                    <li>Браузер, используемый для подключения к приложению.</li>
                </ul>
                <p className="pr-text">Данные, которые Вы вводите, обрабатываются нашим приложением, сохраняются только на Вашем
                    устройстве. Сбор этих данных необходим, чтобы Вы могли сохранить результаты работы в текущей сессии.
                    Используя данное приложение, Вы подтверждаете свое согласие с политикой конфиденциальности.
                </p>
            </div>
            <div className="download-doc" onClick={downloadPrivacy}>
                Скачать документ
            </div>
        </div>

    )

}
export default Privacy