import {FC, useEffect, useState} from "react";
import './notification.css'

interface NotificationProps {
	message: string
	onClose: () => void
}

export const Notification: FC<NotificationProps> = ({message, onClose}) => {
	
	const [visible, setVisible] = useState(false);
	
	useEffect(() => {
		setVisible(true);
		const timer = setTimeout(() => {
			setVisible(false);
			setTimeout(onClose, 500); // Задержка для завершения анимации
		}, 3000);
		
		return () => {
			clearTimeout(timer);
		};
	}, [onClose]);
	
	return (
		<>
			<div className={`notification__container ${visible ? 'show' : ''}`}>
				<p className="notification__text">{message}</p>
			</div>
		</>
	)
}