import React, {FC, SetStateAction} from "react";
import {Route, Routes} from "react-router-dom";
import Privacy from "./components/privacy/Privacy";
import MainTable from "./components/table/MainTable";

export const AppRoutes: FC = () => {
	return (
		<Routes>
			<Route path="/" element={<MainTable />}/>
			<Route path="/privacy" element={<Privacy/>}/>
		</Routes>
	);
};